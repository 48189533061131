<template>
  <v-container fluid align-center class="pt-15">
    <CustomCardTooltip ref="tooltipGuide" :tooltips="tooltips" />
    <v-row class="mt-5 mx-5">
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              <v-row no-gutters>
                <v-col cols="3">
                  <span class="font-weight-bold">Filtros:</span>
                </v-col>
                <v-col cols="9" class="text--secondary" >
                  <v-fade-transition leave-absolute>
                    <span v-if="open"></span>
                    <v-row v-else no-gutters style="width: 100%">
                      <v-col cols="3">
                        <span class="font-weight-bold">Tipo de periodo: </span> <span style="color:#5271ff;">{{ typePeriod }}</span>
                      </v-col>
                      <v-col cols="3">
                        <span class="font-weight-bold">Periodo:</span> <span style="color:#5271ff;">{{ record.periodo }}</span>
                      </v-col>
                      <v-col cols="3">
                        <span class="font-weight-bold">Empresas:</span> <span style="color:#5271ff;">{{ record.empresas.length + ' seleccionadas' }}</span>
                      </v-col>
                    </v-row>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="space-around" no-gutters>
                <v-col cols="3">
                  <v-select v-model="record.tipoPeriodo" label="Tipo de periodo" :items="catalogues.tipos_periodos" item-text="text" item-value="value"
                  prepend-icon="mdi-calendar-check" dense class="ml-5 mr-2" style="max-width: 290px;" :disabled="loading" @change="setDateRecord()"></v-select>
                </v-col>
                <v-col cols="3">
                  <template v-if="record.tipoPeriodo === 'mensual'">
                    <v-menu ref="menuRecord" v-model="record.menu" :close-on-content-click="false" :return-value.sync="record.periodo" 
                    transition="scale-transition" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="record.periodo" label="Periodo" v-bind="attrs" dense v-on="on" prepend-icon="mdi-calendar" class="mx-2" style="max-width: 190px;" :disabled="loading" readonly></v-text-field>
                      </template>
                      <v-date-picker v-model="record.periodo" type="month" locale="es" style="background-color: #f1f1f1">
                        <v-spacer></v-spacer>
                        <v-btn text color="blue-grey" @click="record.menu = false">
                          Cancelar
                        </v-btn>
                        <v-btn text color="success" @click="$refs.menuRecord.save(record.periodo); changeControl()">
                          Aceptar
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </template>
                  <template v-else-if="record.tipoPeriodo === 'anual'">
                    <v-select v-model="record.periodo" label="Periodo" :items="catalogues.years" item-text="text" item-value="value"
                    prepend-icon="mdi-calendar" dense class="mx-2" style="max-width: 190px;" light hide-details :disabled="loading" @change="changeControl()"></v-select>
                  </template>
                </v-col>
                <v-col cols="3">
                  <v-select v-model="record.empresas" label="Empresas" multiple :items="catalogues.empresas" item-text="text" item-value="value"
                  prepend-icon="mdi-domain" dense class="ml-5 mr-2" style="max-width: 290px;" :disabled="loading" @change="changeControl()"></v-select>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col v-show="!errors.kpis" cols="12">
        <v-card min-height="150" min-width="300" class="pa-5" style="display: flex; align-items: center;"> 
          <v-row>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
                <v-card-title class="justify-center font-weight-bold">
                  Circularidad
                  <v-btn icon class="tooltipOne" @click="$refs.tooltipGuide.start(0)">
                    <v-icon small>mdi-help-circle</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(8 124 23); font-weight: bold;" class="pt-10">
                  {{ kpis.circularidad.valor }}
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
                <v-card-title class="justify-center font-weight-bold">
                  Generación de residuos
                  <v-btn icon class="tooltip2" @click="$refs.tooltipGuide.start(1)">
                    <v-icon small>mdi-help-circle</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(231 121 36); font-weight: bold;" class="pt-10">
                  {{ kpis.residuos.valor }}
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
                <v-card-title class="justify-center font-weight-bold">
                  Recuperación
                  <v-btn icon class="tooltip3" @click="$refs.tooltipGuide.start(2)">
                    <v-icon small>mdi-help-circle</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;" class="pt-10">
                  {{ kpis.recuperacion.valor }}
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="150" min-width="300" max-width="320">
                <v-card-title class="justify-center font-weight-bold">
                  Residuos a relleno
                  <v-btn icon class="tooltip4" @click="$refs.tooltipGuide.start(3)">
                    <v-icon small>mdi-help-circle</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;" class="pt-10">
                  {{ kpis.relleno.valor }}
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12">
        <v-card>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col v-show="!errors.materiales_entrada" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Materiales de entrada
            <v-btn icon class="tooltip5" @click="$refs.tooltipGuide.start(4)">
              <v-icon small>mdi-help-circle</v-icon>
            </v-btn>
          </v-card-title>
          <div class="container">
            <Doughnut :chartData="chartData.materiales_entrada.data" :options="chartData.materiales_entrada.options"/>
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col v-show="!errors.recuperacion_material" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Recuperación de material
            <v-btn icon class="tooltip6" @click="$refs.tooltipGuide.start(5)">
              <v-icon small>mdi-help-circle</v-icon>
            </v-btn>
          </v-card-title>
          <div class="container">
            <Doughnut :chartData="chartData.recuperacion_material.data" :options="chartData.recuperacion_material.options"/>
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-5 mx-5">
      <v-col v-show="!errors.historico_materiales" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Histórico de materiales
            <v-btn icon class="tooltip7" @click="$refs.tooltipGuide.start(6)">
              <v-icon small>mdi-help-circle</v-icon>
            </v-btn>
          </v-card-title>
          <div class="container">
            <Bar :chartData="chartData.historico_materiales.data" :options="chartData.historico_materiales.options"/>
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col v-show="!errors.medidas_circularidad" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%"
        style="display: flex !important; flex-direction: column;">
          <v-card-title class="justify-center font-weight-bold">Medidas de circularidad
            <v-btn icon class="tooltip8" @click="$refs.tooltipGuide.start(7)">
              <v-icon small>mdi-help-circle</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text style="flex-grow: 1; overflow: auto;">
            <v-list-item-group v-model="selectCircularityMeasure" active-class="success--text">
              <template v-for="(item, idx) in medidas_circularidad">
                <v-list-item :key="idx" @click="dialogs.circularityMeasures = true">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.id.replace('MATERIALES ', '').replace('RESIDUOS ', '')"></v-list-item-title>
                      <v-list-item-subtitle class="text--primary" v-text="item.sugerencia"></v-list-item-subtitle>
                      <v-list-item-subtitle v-text="item.medida"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.ahorro"></v-list-item-action-text>
                      <v-icon v-if="!item.comprometido" color="grey lighten-1">
                        mdi-check-circle
                      </v-icon>
                      <v-icon v-else color="success">
                        mdi-check-circle
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="idx <= medidas_circularidad.length - 1" :key="'div-' + idx"></v-divider>
              </template>
            </v-list-item-group>
          </v-card-text>
        </v-card>  
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card> 
      </v-col>
    </v-row>
    <v-overlay absolute :value="loading" opacity="0.8">
      <div class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Cargando...</p>
      </div>
    </v-overlay>
    <v-overlay absolute :value="record.loading" opacity="0.8">
      <div class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Generado reporte...</p>
      </div>
    </v-overlay>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
    <v-dialog v-model="dialogs.circularityMeasures" persistent max-width="390">
      <v-card style="margin-top: 0; background-color: white;">
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea actualizar el compromiso con la medida de circularidad?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="blue-grey" @click="cancelNotification()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="success" @click="updateCircularityMeasure()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import services from '@/utils/services'
import moment from 'moment-timezone'
import Bar from '../Charts/StackedBar.js'
import Doughnut from '../Charts/Donut.js'
import downloadjs from 'downloadjs'
import _ from 'lodash'
import CustomCardTooltip from "@/components/CustomCardTooltip.vue";

export default {
  name: 'dashboardStandard',
  components: {CustomCardTooltip, Bar, Doughnut },
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    errors: {
      kpis: true,
      materiales_entrada: true,
      recuperacion_material: true,
      historico_materiales: true,
      medidas_circularidad: true
    },
    msgError: '',
    catalogues: {
      tipos_periodos: [
        { text: 'Mensual', value: 'mensual' },
        { text: 'Anual', value: 'anual' }
      ],
      years: [],
      empresas: []
    },
    record: {
      loading: false,
      tipoPeriodo: 'anual',
      periodo: '',
      menu: false,
      empresas: []
    },
    kpis: {
      circularidad: {
        valor: ''
      },
      residuos: {
        valor: ''
      },
      recuperacion: {
        valor: ''
      },
      relleno: {
        valor: ''
      },
    },
    chartData: {
      materiales_entrada: {
        data: null,
        options: null 
      },
      recuperacion_material: {
        data: null,
        options: null
      },
      historico_materiales: {
        data: null,
        options: null
      }
    },
    medidas_circularidad: [],
    selectCircularityMeasure: null,
    dialogs: {
      circularityMeasures: false,
    },
    tooltips:[
      {
        selector: '.tooltipOne',
        title: 'Título genérico',
        description: '<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, enim?</span>',
        position: 'right',
      },
      {
        selector: '.tooltip2',
        title: 'Título genérico',
        description: '<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, enim?</span>',
        position: 'right',
      },
      {
        selector: '.tooltip3',
        title: 'Título genérico',
        description: '<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, enim?</span>',
        position: 'right',
      },
      {
        selector: '.tooltip4',
        title: 'Título genérico',
        description: '<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, enim?</span>',
        position: 'right',
      },
      {
        selector: '.tooltip5',
        title: 'Título genérico',
        description: '<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, enim?</span>',
        position: 'right',
      },
      {
        selector: '.tooltip6',
        title: 'Título genérico',
        description: '<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, enim?</span>',
        position: 'right',
      },
      {
        selector: '.tooltip7',
        title: 'Título genérico',
        description: '<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, enim?</span>',
        position: 'right',
      },
      {
        selector: '.tooltip8',
        title: 'Título genérico',
        description: '<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, enim?</span>',
        position: 'right',
      },
    ]
  }),
  mounted() {
    this.setCatYears()
    this.setCompanies()
    this.setDateRecord()
  },
  computed: {
    company() {
      return this.$store.state.general.company
    },
    typePeriod() {
      return _.capitalize(this.record.tipoPeriodo)
    },
    report() {
      return this.$store.state.general.report
    }
  },
  watch: {
    company: function (val) {
      if (val) {
        this.record.empresas = []
        this.record.empresas.push(val)
      }
      this.changeControl()
    },
    report: function (val) {
      if (val) {
        this.getReport()
      }
    }
  },
  methods: {
    ...mapActions('general', [
      'setReport'
    ]),
    setCatYears() {
      let year = parseInt(moment().format('YYYY'))
      for (let i = 0; i < 5; i++) {
        this.catalogues.years.unshift((year - i).toString())
      }
    },
    setCompanies() {
      const empresas = this.$session.get('companies')
      for (let empresa of empresas) {
        this.catalogues.empresas.push({
          text: empresa.nombre,
          value: empresa.id
        })
      }
      if (this.$store.state.general.company) {
        this.record.empresas.push(this.$store.state.general.company)
      }
    },
    setDateRecord() {
      this.loading = true
      this.setErrors(true)
      if (this.record.tipoPeriodo === 'mensual') {
        this.record.periodo = moment().format('YYYY-MM')
      }
      else if (this.record.tipoPeriodo === 'anual') {
        this.record.periodo = moment().format('YYYY')
      }
      setTimeout(() => {
        this.getData()
      }, 200);
    },
    setErrors(val) {
      for (let key in this.errors) {
        this.errors[key] = val
      }
    },
    changeControl() {
      this.loading = true
      this.setErrors(true)
      setTimeout(() => {
        this.getData()
      }, 200);
    },
    getDashboard() {
      let params = {
        empresa: this.$store.state.general.company,
        tipo_periodo: this.record.tipoPeriodo,
        periodo: this.record.periodo,
        empresas: this.record.empresas
      }
      this.axios.get(services.routes.ec.dashboard, { params })
        .then(response => {
          this.loading = false
          this.setErrors(false)
          this.kpis = response.data.data.kpis
          this.chartData.materiales_entrada = response.data.data.materiales_entrada
          this.chartData.materiales_entrada.options = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  var dataset = data.datasets[tooltipItem.datasetIndex];
                  var total = dataset.data.reduce(function(previousValue, currentValue) {
                    return previousValue + currentValue;
                  });
                  var currentValue = dataset.data[tooltipItem.index];
                  //var percentage = Math.floor(((currentValue / total) * 100)+0.5);
                  return currentValue + "%";
                }
              }
            },
          }
          this.chartData.historico_materiales = response.data.data.historico_materiales
          console.log(this.chartData.historico_materiales)
          this.chartData.recuperacion_material = response.data.data.recuperacion_material
          this.medidas_circularidad = response.data.data.medidas_circularidad
          this.chartData.recuperacion_material.options = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  var dataset = data.datasets[tooltipItem.datasetIndex];
                  var total = dataset.data.reduce(function(previousValue, currentValue) {
                    return previousValue + currentValue;
                  });
                  var currentValue = dataset.data[tooltipItem.index];
                  //var percentage = Math.floor(((currentValue / total) * 100)+0.5);
                  return currentValue + "%";
                }
              }
            },
          }
        })
        .catch(error => {
          this.loading = false
          this.setErrors(false)
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
    },
    getData() {
      if (this.$store.state.general.company) {
        if (this.record.empresas.length) {
          this.getDashboard()
        }
        else {
          this.loading = false
          this.error = true
          this.msgError = 'Debe seleccionar al menos una empresa'
        }
      }
    },
    cancelNotification() {
      this.selectCircularityMeasure = null
      this.dialogs.circularityMeasures = false
    },
    updateCircularityMeasure(id) {
      this.loading = true
      this.setErrors(true)
      setTimeout(() => {
        let medidas = JSON.parse(JSON.stringify(this.medidas_circularidad))
        let compromisos = []
        medidas[this.selectCircularityMeasure].comprometido = !medidas[this.selectCircularityMeasure].comprometido
        compromisos = medidas.filter(value => value.comprometido)
        let data = {
          empresa: this.$store.state.general.company,
          compromisos: compromisos
        }
        this.axios.put(services.routes.ec.circularityMeasure, { data })
          .then(response => {
            this.medidas_circularidad[this.selectCircularityMeasure].comprometido = !this.medidas_circularidad[this.selectCircularityMeasure].comprometido
            this.selectCircularityMeasure = null
            this.dialogs.circularityMeasures = false
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
            this.setErrors(false)
          })
      }, 200);
    },
    getReport() {
      this.record.loading = true
      let params = {
        empresa: this.$store.state.general.company,
        tipo_periodo: this.record.tipoPeriodo,
        periodo: this.record.periodo,
        empresas: this.record.empresas,
        tipo_reporte: 'economia-circular'
      }
      this.axios.get(services.routes.ec.report, { params })
        .then(response => {
          let data = response.data.data
          let year = (this.record.tipoPeriodo === 'anual') ? this.record.periodo : this.record.periodo.split('-')[0]
          downloadjs('data:application/pdf;base64,' + data, 'Reporte_' + year + '.pdf' , 'application/pdf')
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.setReport({ val: false })
          this.record.loading = false
        })
    },
  }
}
</script>

<style scoped>
  .text-normal {
    text-transform: none !important;
  }
  .small {
    max-width: 600px;
  }
  .container.fill-height {
    justify-content: space-around;
    margin: auto;
  }
  .v-card {
    margin-top: 10px;
    background-color: rgb(227 227 227 / 28%)
  }
  #card-cards {
    margin-top: 10px;
  }
  .card {
    display: flex;
    justify-content: space-around;
  }
  .v-picker__actions {
    background-color: #f1f1f1;
  }
</style>
